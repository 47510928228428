.add-new-post__editor .ql-container,
.add-new-post__editor .ql-editor {
  min-height: 200px;
}

.image-gallery-upload {
  height: 50px;
  overflow: auto;
}

.avatar-preview {
 height: 100px;
 width: 100px;
 object-fit: cover;
 object-position: center;
 border-radius: 50px;
}

.remove-button {
  position: absolute;
  top: -12px;
  cursor: pointer;
  right: 0px;
  font-size: 21px;
  font-weight: 900;
  color: red;
}

.avatar-box { position: relative;}
.avatar-upload {
  height: 40px;
  position: relative;
  object-fit: cover;
  object-position: center;
  width: 40px;
  display: flex;
  align-items: center;
  font-size: 25px;
  color: white;
  border-radius: 50%;
}
.avatar-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  height: 80px;
  width: 80px;
  display: flex;
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  line-height: normal;
  font-weight: bold;
  border-radius: 40px;
  border: 1px solid #eee;
  text-align: center;
  text-transform: uppercase;
  color: black;
}

.days-to-go { position: relative; }
.days-to-go::after {
  content: 'USD';
  font-weight: 800;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%); }
