.hover-opacity:hover {opacity: 0.4;}
.hlTfmo { margin-bottom: 0px !important; }
.kJnpoy { border: 1px solid #DDD; padding: 7px 12px; }
label { margin: 0px };

@media screen and (min-width: 1400px) {
  .col-xxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
.app {
  min-height: 100vh;
  display: flex;
  background-color: #F0F4F5;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;
}
.col-section { 
  margin: 5px;
  height: 300px;
  padding: 20px;
}
.only-mobile { display: none }
@media screen and (max-width: 800px) {
  .only-desktop { display: none }
  .only-mobile { display: block }
}

.images-container {
  min-height: 100px;
  border-radius: 15px;
  border: 1px solid grey;
  padding:10px;
  display: flex;
}

.image-in-container {
  height: 100px;
  object-fit: contain;
}

.padding-container { padding:  20px !important;}

.navbar-colored { color: white !important; background: #004F5D !important }
.minimal-input {
 background-color: unset;
 color: white;
 border: unset;
 border-bottom: 1px solid white;
border-radius: 10px;
padding-left: 10px;
}

.inline {
  display: inline;
}
.cian {
  color: #35BCBA;
}
.token-image {
  -webkit-box-shadow: 0px 7px 30px -7px #FFF, 5px 5px 15px 5px rgba(0,0,0,0.45); 
  box-shadow: 0px 7px 30px -7px #FFF, 5px 5px 15px 5px rgba(0,0,0,0.45);
  border-radius: 50%;
  background-color: white;
}
.main-content-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.card-post__author-avatar {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.ql-editor {
  color: black;
}
.hFJVck { border: 1px solid #ddd; }
.material-icons {
  font-size: 20px !important;
  top: 0 !important;
}
.card {
  background: #10192E
}

.growable {
  flex-wrap: wrap;
  max-width: unset;
  flex-grow: 1;  
}

.btn-primary {
  background-color: #35BCBA !important;
  border-color: #35BCBA !important;
}
.btn-primary:hover {
  background-color: rgb(42, 146, 144);
  border-color: #35BCBA;
}

.divider {
  background-color: #f2f2f2;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}


$cian: #26E4C4;

.white { color: white }
.black { color: black !important }
strong { font-family: 'DIN'; }
.cian { color: $cian }
.bold { font-family: 'DIN'; }
.inline { display: inline; }
.title { font-size: calc(40px + 2.5vw); }
.subtitle { font-size: calc(20px + .85vw); font-family: Apercu; color: #555}
.brand {font-size: calc(20px + .85vw); font-family: DIN; color: #555 }
.subsubtitle { font-size: calc(11px + .85vw); }
.description { font-size: 18px }
.divider {
  background-color: #eeeeee;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
}
.lfm__typing_effect_text { font-family: DIN; color: #26E4C4 }
.activity { 
  background: white;
  overflow-y: auto;
  overflow-x: hidden;
}
.activity:nth-child(odd) { background: #F8F8F8 }
.navbar-colored { color: white !important; background: #004F5D !important }
.navbar-container {
  width: 50%;
  justify-content: space-around;
}

.nav-item { 
  display: flex;
  .nav-link {
    padding: 0px !important;
    margin: 0px !important;
  }
  p { text-transform: none !important; }
}

.bottom-tab {
  display: flex;
  color: #CCC;
  flex-direction: column;
}
.bottom-tab-active {
  color: white;
}

.select-wrapper {
  position: relative;
}

.select-wrapper:before {
  content: "\25bc";
  color: #004F5D;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 4px;
  pointer-events: none;
}

select {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: 20px;
  border-color: #004F5D;
  font-size: 12px;
  color: #004F5D;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

.btn {
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.btn-outline-secondary { 
  color: #004F5D !important; 
  border-color: #004F5D !important; 
}
.btn-outline-secondary:hover { 
  color: #FFF !important; 
  background-color: #004F5D !important;
}
.btn-outline-secondary:active {
  color: #FFF !important; 
  background-color: #004F5D !important;
}

.highlighteable:hover {animation: enlight 400ms forwards}

.container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.app {
  min-height: 100vh;
  display: flex;
  background-color: #F0F4F5;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;
}
.box-section { 
  height: 300px !important;
  padding: 20px !important;
  width:  100% !important;
}

.bottom-navbar {
  display: flex ;
  justify-content: space-around;
  align-items: center;
}
.bottom-fixed {
  position: fixed;
  bottom: 0;
  height: 55px;
  background-color: #004F5D;
  border-top: 1px solid #ddd;
  width: 100%;
  left: 0;
}
.only-mobile {display: none}
.only-desktop {display: block}
@media screen and (max-width: 800px) {
  .only-mobile {display: block}
  .container { width: 90%;}
  .only-desktop {display: none !important}
}

.fade-out { 
  animation: fadeOut .8s linear forwards;
  animation-iteration-count: 1;
}

.fade-in { 
  animation: fadeIn .8s linear forwards;
  animation-iteration-count: 1;
}
.dropdown-toggle {
  display: flex;
  background-color: transparent !important;
  border-color:  transparent !important;
}
.dropdown-toggle::after{
  margin: auto;
  margin-left: 5px
}
.dropdown-item {
  display: flex !important;
}

@keyframes move-horizontal {
	0% { transform: translatex(0px); }
	50% { transform: translatex(100px); }
	100% { transform: translatex(0px); }
}
@keyframes move-horizontal-inverted {
	0% { transform: translatex(0px) scale(-1); }
	50% { transform: translatex(-80px) scale(-1); }
	100% { transform: translatex(0px) scale(-1); }
}
@keyframes float {
	0% { transform: translatey(0px); }
	50% { transform: translatey(-10px); }
	100% { transform: translatey(0px); }
}
@keyframes enlight {
  100% { opacity: 0.6; }
}
@-webkit-keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0; display: none; visibility: hidden;}
}
@-moz-keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0; display: none; visibility: hidden;}
}
@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0; display: none; visibility: hidden;}
}
@keyframes grow {
  0% {opacity: 0; width: 0px; }
  100% {opacity: 1; width:120px;}
}