@font-face {
  font-family: 'Apercu';
  font-style: medium;
  font-weight: 400;
  src: url('../fonts/FontsFree-Net-ApercuMedium.ttf'); 
}
@font-face {
  font-family: 'DIN';
  font-style: medium;
  font-weight: 400;
  src: url('../fonts/DINRoundPro-Medi.ttf'); 
}